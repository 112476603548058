// require('../core/subs/d3po_ITK/d3po_ITK.js');
require('./psmod_app');
require('./psmod_service');
require("../core/js/angular-slick/dist/slick.js");
require("../core/js/slick-carousel/slick/slick.mod.min.js")
require('../core/subs/tool_templates/shared/js/shared.js');
require('../core/subs/tool_templates/src/blog_module/blog_module.js');
require('../core/subs/tool_templates/src/image_menu/image_menu.js');
require('../core/subs/tool_templates/src/manual_slideshow/manual_slideshow.js');
require('../core/subs/tool_templates/src/mega_menu/mega_menu.js');


require('../core/subs/tool_templates/src/blog_module/scss/blog_module.scss');
require('../core/subs/tool_templates/src/image_menu/scss/image_menu.scss');
require('../core/subs/tool_templates/src/manual_slideshow/scss/manual_slideshow.scss');
require('../core/subs/tool_templates/src/mega_menu/scss/mega_menu.scss');
require('../core/js/slick-carousel/slick/slick-theme.css');
require('../core/js/slick-carousel/slick/slick.css');
require('../scss/psmod_module.scss');
require('../core/css/dimensions.css');
// require('');

__webpack_public_path__ = "its/me";
const runtime = require('serviceworker-webpack-plugin/lib/runtime');


document.addEventListener('DOMContentLoaded', function () {

    console.log("mod app.js running!");
    if ('serviceWorker' in navigator) {
      const registration = runtime.register();
      console.log(`registration = ${registration}`);
    }

});
