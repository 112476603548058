(function(){
console.log("angular running!");

var app = angular.module("pictureShow");
app.service("ShowData",['$http',"$sce","$rootElement",function($http,$sce,$rootElement){

    console.log("ShowData running!");
    var boss = this;
    this.app = "ShowData running";

    var iUN = Math.round(Math.random() * 10000);
    this.iUN = iUN;

    this.module_id = $rootElement[0].dataset.module;
    this.module_root = $rootElement[0].dataset.root;
    this.home_url = $rootElement[0].dataset.home;
    this.tool = {};
    this.toolData = [];
    this.tool_data = {};
    this.data_ids = [];
    this.asset_info = [];
    console.log("module id = ",$rootElement);
    this.menu_show = false;
    this.view = "default";

    this.assetData = [];
    this.asset_reference = {};
    this.asset_reference_details = {};
    this.asset_ids = [];
    this.asset_info = [];
    this.asset_details = [];

    //tool_var notes:
    //used in mega_menu
    //i don't want to set them in the service.tool... object because it will end up as bulk in the db
    //i don't want to overwrite the og variables because they are already used a certain way by other tools
    this.tool_asset_ids = [];
    this.tool_asset_info = [];
    this.tool_asset_details = [];

    this.menuData = [];
    this.menu_reference = {};
    this.menu_reference_details = {};
    this.menu_ids = [];
    this.menu_info = [];
    this.menu_details = [];

    this.tool_menu_ids = [];
    this.tool_menu_info = [];
    this.tool_menu_details = [];

    this.pageData = [];
    this.page_reference = {};
    this.page_reference_details = {};//identical to page_reference. (got lazy)
    this.page_ids = [];
    this.page_info = [];
    this.page_details = [];

    this.tool_page_ids = [];
    this.tool_page_info = [];
    this.tool_page_details = [];

    this.THTML = function(html){
      return $sce.trustAsHtml(html);
    };

    this.getAssets = function(aIDs)
    {
      return new Promise(function(resolve, reject) {

        //i need getAssets to run later
        let trans = {};
        trans.task = "getAssets";
        let targ_ids = aIDs || boss.asset_ids
        trans.data = targ_ids.join();

        if(trans.data == "") resolve("no trans data");

        boss.request(trans)
        .then(function(results)
        {
          console.log("places results = ",results);
          if(results != "error"){

            //ShowData.asset_info = results;
            boss.assetData = results;
            boss.assetData.forEach(function(entry)
            {
              //boss.asset_reference[`asset${entry.id}`] = entry;
              boss.asset_reference[entry.id] = entry;
              boss.asset_reference_details[entry.id] = JSON.parse(entry.params);

            });
            //console.log("tool_data = ",ShowData.asset_info);
            console.log("tool_data = ",boss.asset_reference);
            return results;
          }
          //$scope.$apply();
        }).then((result)=>{
          if(targ_ids.length != 0)
          {
            //formerly boss.asset_ids.length
            boss.asset_info = boss.update_asset_info(targ_ids);
            boss.asset_details = boss.update_asset_info(targ_ids,"details");
          }
          resolve();
        }).catch(function(err)
        {
          console.log(`serve getAsset error ${err}`);
          reject("mod getAsset error");
        });

        console.log("getAssets finished running!");

        //return;
      });//Promise
    }//getAssets


    this.update_asset_info = function(dIDs,str)
    {
      let comp_ids = [];
      let mode = str || "default";

      if(dIDs == undefined || typeof dIDs != "object" || dIDs.length < 1)return;

      dIDs.forEach(function(entry){
        if(boss.asset_reference[entry] != undefined){
          if(str != "details"){
            comp_ids.push(boss.asset_reference[entry]);
          }else{
            comp_ids.push(boss.asset_reference_details[entry]);
          }
        }//end if
      });
      return comp_ids;
    }//end update_asset_info


    this.getMenus = function(mIDs)
    {
      return new Promise(function(resolve, reject) {

        //i need getAssets to run later
        let trans = {};
        trans.task = "getMenus";
        let targ_ids = mIDs || boss.menu_ids;
        trans.data = targ_ids.join();

        if(trans.data == "") return;

        boss.request(trans)
        .then(function(results)
        {
          console.log("places results = ",results);
          if(results != "error"){

            //ShowData.menu_info = results;
            boss.menuData = results;
            boss.menuData.forEach(function(entry)
            {
              //boss.menu_reference[`menu${entry.id}`] = entry;
              boss.menu_reference[entry.id] = entry;
              boss.menu_reference_details[entry.id] = JSON.parse(entry.params);

            });
            //console.log("tool_data = ",ShowData.menu_info);
            console.log("tool_data = ",boss.menu_reference);
          }
          //$scope.$apply();
        }).then((result)=>{
          if(boss.menu_ids.length > 0)
          {
            boss.menu_info = boss.update_menu_info(targ_ids);
            boss.menu_details = boss.update_menu_info(targ_ids,"details");
          }
          resolve();
        }).catch(function(err)
        {
          console.log(`serve getMenus error ${err}`);
          reject("mod getMenus error");
        });

        console.log("getMenus finished running!");

        //return;
      });//Promise

    }//getMenus

    this.update_menu_info = function(dIDs,str)
    {
      let comp_ids = [];
      let mode = str || "default";

      if(dIDs == undefined || typeof dIDs != "object" || dIDs.length < 1)return;

      dIDs.forEach(function(entry){
        if(boss.menu_reference[entry] != undefined){
          if(str != "details"){
            comp_ids.push(boss.menu_reference[entry]);
          }else{
            comp_ids.push(boss.menu_reference_details[entry]);
          }
        }//end if
      });
      return comp_ids;
    }//end update_menu_info


    this.getPages = function(pIDs)
    {
      return new Promise(function(resolve, reject) {

        //i need getAssets to run later
        let trans = {};
        trans.task = "getPages";
        let targ_ids = pIDs || boss.page_ids
        trans.data = targ_ids.join();

        if(trans.data == "") return;

        boss.request(trans)
        .then(function(results)
        {
          console.log("places results = ",results);
          if(results != "error"){

            //ShowData.page_info = results;
            boss.pageData = results;
            boss.pageData.forEach(function(entry)
            {
              //boss.page_reference[`page${entry.id}`] = entry;
              boss.page_reference[entry.id] = entry;
              boss.page_reference_details[entry.id] = entry;

            });
            //console.log("tool_data = ",ShowData.page_info);
            console.log("tool_data = ",boss.page_reference);
          }
          //$scope.$apply();
        }).then((result)=>{
          if(boss.page_ids.length != 0)
          {
            boss.page_info = boss.update_page_info(targ_ids);
            boss.page_details = boss.update_page_info(targ_ids,"details");
          }
          resolve();
        }).catch(function(err)
        {
          console.log(`serve getPages error ${err}`);
          reject("mod getPages error");
        });

        console.log("getPages finished running!");

        //return;
      });//Promise

    }//getPages

    this.update_page_info = function(dIDs,str)
    {
      let comp_ids = [];
      let mode = str || "default";

      if(dIDs == undefined || typeof dIDs != "object" || dIDs.length < 1)return;

      dIDs.forEach(function(entry){
        if(boss.page_reference[entry] != undefined){
          if(str != "details"){
            comp_ids.push(boss.page_reference[entry]);
          }else{
            comp_ids.push(boss.page_reference_details[entry]);
          }
        }//end if
      });
      return comp_ids;
    }//end update_page_info

    this.compile_id_list =  function(tAry,tStr)
    {
      let comp_ids = [];
      let targ_array = tAry;

      if(typeof targ_array != "object" || targ_array.length == 0)return [];

      targ_array.forEach(function(entry)
      {
        let array_obj = boss.menu_reference_details[entry];
        if(array_obj[`${tStr}_ids`].length === 0)return;

        array_obj[`${tStr}_ids`].forEach(function(eId){
          let is_in_array = boss.valueChecker({"array":comp_ids,"string":eId,"mod":"index","type":"sna","action":"match"});
          if(is_in_array[0] == -1)
          {
            comp_ids.push(eId);
          }
        });

      });//targ_array

      return comp_ids;

    }//compile_id_list


    this.request = function(gDObj,mode)
    {
      return new Promise(function(resolve, reject) {

        let form_token = FORM_TOKEN;
        let urlMod = gDObj.task;
        let send_data = gDObj.data || "";
        let json_url = mode || "none";
        let site_url = (json_url == "none") ? ["index.php?option=com_psmod&task="
                                              ,urlMod,
                                              "&format=raw&",
                                              form_token,"=1"].join("")
                                              : json_url;
        let my_result = "";

         $http.post(site_url,{data:send_data}).then((result)=>{
            //console.log("result = ",result.data);
            if(result.data == "Invalid Token"){
              //reload page
              console.error(`[request] return results = "Invalid Token" ${result.data}`);
              reject(result.data);
            }else if(result.data == undefined
            || result.data == "" || result.data == [] || typeof result.data == "string" && result.data.indexOf("<!doctype html>") != -1){
              my_result = "error";
              reject();
            }else{
              my_result = result.data;
              resolve(my_result);
            }
        }).catch(function(err){
          console.log("request error ",err);
        });

          //return my_result;
        });//Promise

    };//end request


    this.activate_template = async function(tool,home,mode)
    {
      return new Promise(function(resolve, reject) {
          if(typeof tool != "object" || Object.keys(tool).length === 0)reject();

          if(tool.views == undefined && tool.details != undefined){
            //upgrades from details to views
            tool.views = {};
            tool.views.default = boss.bboy(tool.details);
          }

          boss.current_tool = tool;
          boss.intial_design = boss.bboy(tool.views);//intial_details
          boss.tool = tool;//i want to set the tool here so i don't have to use crew
          let design_id = (tool.design_mode != undefined && tool.design_mode == "pattern") ? tool.design_id : "";

          boss.getDesignData(design_id)
          .then(function(results){
            console.log("places results = ",results);
            if(results != "error" && results != ""){
              let meseeks = results;

              if(results.details != undefined || results.views != undefined)
              {
                boss.design_data = (results.views) ? results.views : results.details;
                //boss.tool.views.default = boss.bboy(boss.design_data);
                //meseeks = boss.design_data;

                // get the templates template_style to form the proper template url
                boss.tool.template_style = results.template_style;

                let temp_design = boss.bboy(boss.design_data);
                let has_views = (boss.tool.views != undefined ) ? true : false;
                let has_default = (temp_design.default != undefined && typeof temp_design.default == "object") ? true : false;

                //merge with current tool
                if(has_views && has_default)
                {
                  //most up to date version - both bookmark and current tool has been converted to use views
                  boss.tool.views = temp_design;

                }else if(has_views && has_default == false)
                {
                  //next lvl, has views conversion against older style json data ('details' data)
                  boss.tool.views.default = temp_design;
                }else if(has_views == false && has_default)
                {
                  //next 3rd lvl, doesn't view conversion against older style json data (details data)
                  boss.tool.details = temp_design.default;
                }else{
                    boss.tool.details = temp_design;
                }
              }//if
            }//if
          })
          .catch(function(err)
          {
            console.log(`boss getDesignData error ${err}`);
          })
          .then(function(){
            //note: i may need to change this for the sake of performance
            let template_str = home || "showcase";
            let template_home = "." + template_str;

            //dynamically chooses display style for templateUrl (along with template_mode)
            let template_style = boss.tool.template_style || "basic";
            let template_mode = mode || template_style;//alt = settings // formerly || 'tool_default'
            let tempNbr = (template_mode != "settings") ? 0 : 1;//used in the injector to distinguish querySelector objects
            let custom_class = (template_mode != "settings") ? " " + boss.tool.views.default.class_pfx + " " + boss.tool.views.default.class_style + " " : "";
            let custom_style = (boss.tool.views.default.sample_style != undefined) ? boss.tool.views.default.sample_style  : "";
            //bugfix for settings display problem - can't use same dimensions as slideshow display

            //clear the stage
            let t_home = (document.getElementById(template_str)) ? document.getElementById(template_str) : document.querySelector(template_home);
            t_home.innerHTML = "";

            let tool_str = escape(JSON.stringify(boss.tool));//formerly crew="'+tool_str+'" & sttngs="' + tool_str + '"


            let tool_class = " " + boss.tool.alias + " " + template_mode + " ";
            let stage = " " + boss.tool.file_name + "_" + template_mode;
            let stage_id = " " + boss.tool.file_name + "_"+  boss.module_id + " ";

            let injection_data = '<div class="' + stage + " " + stage_id + " " + tool_class + custom_class + '" data-cast="' + custom_class
            + '" data-motiv="' + template_mode + '" ' + ' data-marquee="' + boss.tool.file_name + '" data-mode="site" '
            + 'data-module_title="' + boss.tool.module_title + '" data-template_style="' + boss.tool.template_style + '" '
            + 'data-home="' + boss.home_url + '" data-stage="' + stage_id + '" style="' + custom_style + '" data-nav="nav"></div>';
            //marquee is used in templateUrl to set dynamic template.html along with data-home
            //data-motiv is used in template.html ng-if statements

            t_home.innerHTML = injection_data;
            angular.element(t_home).injector().invoke(["$compile",function($compile)
            {
              //let dir_str = "." + tool.alias;
              //let dir_obj = document.querySelectorAll(dir_str);
              //dir_str = stage_id;
              //dir_str = "." + boss.removeSomething(dir_str,' ');
              let dir_str = stage_id;//this is properly done with an underscore
               dir_str = "." + boss.removeSomething(dir_str,' ');
              let dir_obj = document.querySelectorAll(dir_str);//bugfix for multiple slideshows
              let scope = angular.element(t_home).scope();
              //im using tempNbt because this is loaded twice. once for the display the other for settings
              $compile(dir_obj[tempNbr])(scope);
              resolve();
            }]);
          });//then

        });//Promise

    }//activate_template

    this.getDesignData = function(ref_id)
    {
      return new Promise(function(resolve, reject) {
        let trans = {};
        trans.task = "getDesignData";
        let targ_id = ref_id;
        trans.data = targ_id;

        let valid_id = (ref_id == undefined || ref_id == "" || parseInt(ref_id) == NaN || parseInt(ref_id) === 0) ? false : true;
        if(trans.data == "" || valid_id == false ){
          return reject("");
        }//formerly return; watch for errors
        //i need getAssets to run later

        boss.request(trans)
        //boss.http_req(trans)
        .then(function(results)
        {
          resolve(results)
        }).catch(function(err)
        {
          console.log(`boss getDesignData error ${err}`);
          reject("getDesignData error");
        });

        console.log("getDesignData finished running!");

        //return;

      });
    }//getDesignData

    this.bboy = function(obj)
    {
      let meseeks = obj;
      return JSON.parse(JSON.stringify(obj));
    }//break_obj

    this.activate_template_og = async function(tool,home,mode)
    {
      if(typeof tool != "object" || Object.keys(tool).length === 0)return;

      let template_str = home || "showcase";
      let template_home = "." + template_str;
      let template_style = tool.template_style || "basic";
      let template_mode = mode || template_style;//alt = settings // formerly || 'tool_default'
      let tempNbr = (template_mode != "settings") ? 0 : 1;//this puts the settings template second
      let custom_class = (template_mode != "settings") ? " " + tool.details.class_pfx + " " + tool.details.class_style + " " : "";
      let custom_style = (template_mode != "settings" && tool.details.custom_style != undefined) ? tool.details.custom_style : (tool.details.custom_style != undefined) ? tool.details.sample_style  : "";

      //bugfix for settings display problem - can't use same dimensions as slideshow display

      //clear the stage
      let t_home = (document.getElementById(template_str)) ? document.getElementById(template_str) : document.querySelector(template_home);
      t_home.innerHTML = "";

      let tool_str = escape(JSON.stringify(tool));//formerly crew="'+tool_str+'" & sttngs="' + tool_str + '"

      boss.current_tool = tool;
      boss.tool = tool;//i want to set the tool here so i don't have to use crew

      let tool_class = " " + tool.alias + " " + template_mode + " ";//important! triggers directive (tool.alias)
      let stage = " " + tool.file_name + "_" + template_mode;
      let stage_id = " " + tool.file_name + "_" +  boss.module_id + " ";//why tool.id not modul_id?

      let injection_data = '<div class="' + stage + " " + stage_id + " " + tool_class + custom_class + ' pure-h" data-cast="' + custom_class
      + '" data-motiv="' + template_mode + '" ' + ' data-marquee="' + tool.file_name + '" data-mode="site" '
      + 'data-home="' + boss.home_url + '" data-stage="' + stage_id + '" style="' + custom_style + '"></div>';
      //marquee is used in templateUrl to set dynamic template.html along with data-home
      //data-motiv is used in template.html ng-if statements

      t_home.innerHTML = injection_data;
      angular.element(t_home).injector().invoke(["$compile",function($compile)
      {
        //let dir_str = "." + tool.alias;//this may still have a dash instead of the needed underscore
        let dir_str = stage_id;//this is properly done with an underscore
         dir_str = "." + boss.removeSomething(dir_str,' ');
        let dir_obj = document.querySelectorAll(dir_str);//bugfix for multiple slideshows
        let scope = angular.element(t_home).scope();
        //im using tempNbt because this is loaded twice. once for the display the other for settings
        $compile(dir_obj[tempNbr])(scope);
      }]);

    }//activate_template

    this.removeSomething = function(val,char,rep)
    {
      /*
      //sample
      ShowData.removeSomething(ShowData.edit.title,' ');//unnessesary spaces
      //control the spaces
      pal = ShowData.removeSomething(pal,' ','-');
      //replace slashes with dashes
      pal = ShowData.removeSomething(pal,'/','-');
      //make sure there are no double dashes
      pal = ShowData.removeSomething(pal,'-');
      */
      //removes multiple spaces leading and trailing
      let curVal = val;
      //let pattern1 =
      let multi_converter = new RegExp(char + '+','g');//  '/'+ char + '+/g or / +/g
      curVal = curVal.replace(multi_converter,char); //convert all multispaces to space
      let start_converter = new RegExp('^' + char,'g');
      curVal = curVal.replace (start_converter,"");  //remove space from start /^ /g
      let end_converter = new RegExp(char + '$','g');
      curVal = curVal.replace (end_converter,"");  //and end / $/g
      if(rep != undefined && rep != ""){
        let replacer = new RegExp(char,'g');
        curVal = curVal.replace(replacer,rep);
      }
      return curVal;
    };//end removeSomething

    this.clear_redundacy = function (cN,rA) {
      let text = cN,
      redundant_array = (Array.isArray(rA)) ? rA : rA.split(" ");

      redundant_array.forEach(function (entry) {
        let targ = new RegExp(entry,'g');
        text = text.replace(targ,"");
      })

      return text;
    }// clear_redundacy

    this.valueChecker = function(sObj)
		{
			/*
			if found returns an array of string or index values

			example use:
			var isString =  valueChecker({"array":icon_keys,"string":target_string,"mod":"string","type":"ans"});
			if(isString[0] == -1)

			ans = array in string - (was checkStringForArray2) checkStringForArray2
			sna = string in array - (was check array for string);
			*/

			var testString = sObj.string;
			var testArray = sObj.array;
			var modifier = sObj.mod || "index";//other value is name
			var type = sObj.type || "ans";
			var strIndx = [];
			var action = (sObj.action != undefined && sObj.action == "match") ? "match" : "compare";


			strIndx[0] = (modifier == "index") ? -1 : "none";

			for(var i = 0; i < testArray.length; i++)
			{
				var targetString = (type == "ans") ? testString : testArray[i];//
				var testValue = (type == "ans") ? testArray[i] : testString;
				var is_present = "false";

				//does the string - (usually long) have any of the array values
				//"https://youtube.com/#*(&$)*&*(*)whatever".indexOf("youtube")

				if(action == "compare")
				{
					if(targetString.indexOf(testValue) != -1)
					{
						var is_present = "true";
					}
				}else
				{
					if(targetString == testValue)
					{
						var is_present = "true";
					}
				}//end else

				if(is_present == "true")
				{
					if(modifier == "index")
					{
						if(strIndx[0] == -1)
						{

							strIndx = [];
							strIndx = strIndx.concat(i);

						}else
						{
							strIndx = strIndx.concat(i);
						}//end else


					}else{

						if(strIndx[0] == "none")
						{
							strIndx = [];
							strIndx = strIndx.concat(testArray[i]);

						}else
						{
							strIndx = strIndx.concat(testArray[i]);
						}//end else

					}//end else modifier

				}//end if targetString

			}//end for

			return strIndx;

		}//valueChecker

    this.true_target = function(targ,dataStr,tp,lv)
    {
      //let targEl = boss.true_target(ev.target,'moving');
      //boss.true_target(boss_cont,"moduletable","className");//current_el, class_str,mode
      let current_el = targ;
      let lvl = lv || 5;
      let type = tp || "dataset";
      while(current_el && lvl > 0){

        if(type == "dataset" && current_el.dataset[dataStr] != undefined) return current_el;
        if(type == "className" && current_el.className.indexOf(dataStr) != -1) return current_el;

        current_el = current_el.parentNode;
        lvl --;
      }//end while

      return current_el;
    }//true_target

    this.exists = function(item)
    {
      return (item != undefined && item != "") ? true : false;
    }//exists

    this.restructure = function(dest,oProp,nProp)
    {
      //if old property exists restructure it to the new property and delete the old one
      if(dest[oProp] != undefined )
      {
        let temp_value = dest[oProp];
        dest[nProp] = temp_value;
        delete dest[oProp];
      }//if

      //send back the new property
      return (boss.exists(dest[nProp])) ? dest[nProp] : false;

    }//restructure

    this.test_value = function(obj_val,opr,ary)
    {
      //this tests 2 values against each other
      let value = obj_val;
      switch (opr) {
        case '==':
          return ary.some(function(entry){
            entry == value;
          });
        break;
        default:
        return ary.every(function(entry){
          entry != value;
        });
      }//switch
    }//test_value


    this.get_device_size = function()
    {
      let screen_width = document.body.clientWidth;
      let device_size = (screen_width < 480) ? "small" :
      (screen_width > 480 && screen_width < 768) ? "medium" :
      "large";
      return device_size;
    }//get_device_size

    this.image_object_converter = function(cpar)
    {
      let data = cpar;
      if(data.img_obj != undefined && data.img_obj[0] == undefined)
      {
        let temp_obj = boss.bboy(data.img_obj);
        data.img_obj = [];
        data.img_obj[0] = (boss.exists(temp_obj)) ? temp_obj : {} ;
        data.img_obj[0].url = data.url;

        if(boss.exists(data.canvas))
        {
          data.img_obj[0].canvas = boss.bboy(data.canvas);
        }//if
      }//if

      return data;

    }//image_object_converter

    this.prep_text = function(tObj,sT)
    {
      let short_text = tObj.link.short_text || ""
      let body_text = tObj.body.raw || "";
      let use_short_text = (boss.exists(sT) && boss.exists(short_text) && boss.exists(body_text)) ? sT : false;
      let body_text_array = [];

      if(use_short_text == true)
      {
        body_text_array = body_text.split(short_text);
        //what happens to the array if you paste the entire short text instead of an ending phrase?
        body_text = `${body_text_array[0]}${short_text}`;
      }//if

      return body_text;

    }//prep_text


    this.current_asset = function(actn)
    {
      let asset = actn;
      let params = JSON.parse(actn.params);
      let publish_up = (boss.exists(params.publish_up)) ? new Date(params.publish_up) : "none";// formerly asset.publish_up
      let publish_down = (boss.exists(params.publish_down)) ? new Date(params.publish_down) : "none";

      //i need the timestamp for the saved dates

      let now = new Date();
      let now_stamp = now.getDate();
      let up_stamp = (publish_up == "none" || publish_up == "Invalid Date" || now_stamp > publish_up) ? "current" : "premature";
      let down_stamp = (publish_down == "none" || publish_down == "Invalid Date" ||now_stamp < publish_down) ? "current" : "expired";

      return (up_stamp == "current" && down_stamp == "current") ? true : false;

    }// current_asset

    this.classMkr = function (cObj)
    {
      let generic_name = cObj.name,
      unique = `_${cObj.iUN}`,
      item_id = (cObj.id) ? `_${cObj.id}` : "",
      index = (cObj.ndx != undefined && cObj.ndx !== "") ? `_${cObj.ndx}` : "";

      let full = `${generic_name}${unique}${item_id}${index}`,
      original = `${generic_name}${unique}${item_id}`,
      alternate = `${generic_name}${unique}${index}`,
      short_unique = `${generic_name}${unique}`,
      short_index = `${generic_name}${index}`,
      short_id = `${generic_name}${item_id}`,
      generic = `${generic_name}`;

      let compiled_class =    `${full} ${original} ${alternate} ${short_unique}
      ${short_index} ${short_id} ${generic}`;
      boss.removeSomething(compiled_class," ");

      return compiled_class;
    }//classMkr

    this.prep_stars = function (bObj)
    {
      if(bObj.initiated != true || bObj.motiv == "settings")return;

      if(bObj.ready_4_action != undefined &&
      bObj.ready_4_action != false &&
      bObj.my_stars.length > 0){
        // run this only one time
        boss.convert_stars(bObj)
        .then(function () {
          bObj.ready_4_action = true;
        });
      }//if

    }// prep_stars

    this.convert_stars = async function (bObj)
    {
      let targ = bObj;
      stars_array = targ.my_stars;

      // reforms my_stars array without expired & premature images
      // my_stars,initiated,mode,motiv
      let temp_array = [];
      temp_array = temp_array.concat(stars_array);
      stars_array.length = 0;
      await temp_array.forEach(function (entry) {
        let is_current = boss.current_asset(entry);
        if(is_current)
        {
          stars_array.push()
        }//if
      });
    }// convert_stars


}]);//end ShowData


})();//end closure
