(function(){
console.log("angular running!");
// const angular = require('angular');

var app = angular.module("pictureShow", ['slick']);
app.controller('showTimeController',["ShowData","$scope","$timeout","$rootElement",function(ShowData,$scope,$timeout,$rootElement){
  //slickCarousel
    console.log("showTimeController running!");
    var boss = this;
    this.service = ShowData;
    this.app = "showtime app running";

    $scope.$watch(function(){return ShowData.app}, function (newValue, oldValue,scope) {
        if(newValue){
        console.log("app change detected");

        boss.app = newValue;
      }
    }, true);

    $scope.$watch(function(){return ShowData.tool.menu_ids}, function (newValue, oldValue, scope) {

      if (newValue){

          ShowData.menu_ids = newValue;

      }//if
    }, true);

    this.getData = function()
    {
      return new Promise(function(resolve, reject) {
        let trans = {};
        trans.task = "places";
        trans.data = ShowData.module_id;

        //this await here works to delay the processing of this function until the data returns from the db
        boss.service.request(trans)
        .then(function(results)
        {
          console.log("places results = ",results);
          if(results != "error"){

            ShowData.app = results;
            ShowData.tool_data = JSON.parse(results[0].tool_data);

            //protect from empty data_ids
            ShowData.data_ids = (results[0].data_ids.indexOf(",") != -1) ?  results[0].data_ids.split(",") : results[0].data_ids;
            console.log("tool_data = ",ShowData.tool_data);
            resolve();
          }
          //$scope.$apply();
        }).catch(function(err)
        {
          console.log(`psmod_app getData error ${err}`);
        });

        console.log("getData finished running!");

        //return;
      });//Promise

    }//getData

    this.getAssets = function()
    {
      //deprecated
      return new Promise(function(resolve, reject) {
          //i need getAssets to run later
          let trans = {};
          trans.task = "getAssets";
          trans.data = ShowData.data_ids.join();

          if(trans.data == "") return;

          boss.service.request(trans)
          .then(function(results)
          {
            console.log("places results = ",results);
            if(results != "error"){

              ShowData.asset_info = results;

              console.log("tool_data = ",ShowData.asset_info);
              resolve();
            }
            //$scope.$apply();
          }).catch(function(err)
          {
            console.log(`psmod_app getData error ${err}`);
          });

          console.log("getAssets finished running!");

          //return;
        });//Promise
    }//getAssets

    this.$onInit = async function() {

      boss.getData()
      //await boss.getAssets()
      .then(function(){
        //once the assests are returned bootstrap the tool_showcase
        if(typeof boss.service.tool_data == "object" && boss.service.tool_data.length  != 0){
          //formerly asset_info
          let home_str = "tool_showcase" + boss.service.module_id;
          boss.service.activate_template(boss.service.tool_data,home_str);
        }//end if
      });
        $timeout(function(){
          //$scope.$apply();
          console.log("data-module = ",boss.service.module_id);

        },0,true).then(function(){
          //sTCtrlr.showDivs(slideIndex);
        });

      return;
    };//onInit




}]);//end ShowController


})();//end closure
